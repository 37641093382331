import { NgModule }              from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { SharedModule }                          from '../shared/shared.module';
import { TableModule }                           from '../table/table.module';
import { TillpaymentsActivatePosComponent }      from './tillpayments.activate-pos.component';
import { TillpaymentsOnlineSettingsComponent   } from './tillpayments.online-settings.component';
import { TillpaymentsPosSettingsComponent }      from './tillpayments.pos-settings.component';
import { TillpaymentsService }                   from './tillpayments.service';
import { TillpaymentsSettingsComponent }         from './tillpayments.settings.component';
import { TillpaymentsStates }                    from './tillpayments.states';
import { TillpaymentsTerminalsComponent }        from './tillpayments.terminals.component';
import { TillpaymentsTransactionComponent }      from './tillpayments.transaction.component';

@NgModule({
  declarations: [
    TillpaymentsActivatePosComponent,
    TillpaymentsOnlineSettingsComponent,
    TillpaymentsSettingsComponent,
    TillpaymentsPosSettingsComponent,
    TillpaymentsTerminalsComponent,
    TillpaymentsTransactionComponent
  ],
  entryComponents: [
    TillpaymentsActivatePosComponent,
    TillpaymentsTerminalsComponent,
    TillpaymentsTransactionComponent
  ],
  imports: [
    SharedModule,
    TableModule,
    UIRouterUpgradeModule.forChild({ states: TillpaymentsStates })
  ],
  providers: [
    TillpaymentsService
  ]
})
export class TillpaymentsModule {}
