import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';

import { ApiService }                    from '../api/api.service';
import { SessionServiceAjs }             from '../sessions/session.service.ajs';
import { TillpaymentsSettingsComponent } from './tillpayments.settings.component';

export const TillpaymentsStates : Array<NgHybridStateDeclaration> = [
  {
    component: TillpaymentsSettingsComponent,
    name     : 'app.tillpayments-settings',
    url      : '/tillpayments-settings',
    resolve  : [
      {
        deps     : [ SessionServiceAjs ],
        resolveFn: ( sessionService : SessionServiceAjs ) => sessionService.refresh(),
        token    : 'session'
      },
      {
        deps     : [ ApiService ],
        resolveFn: ( apiService : ApiService ) => apiService.get('/company_integrations'),
        token    : 'settings'
      }
    ]
  }
];
