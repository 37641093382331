import {
  Component,
  Inject,
  Input,
  OnInit
} from '@angular/core';

import { VehicleModel } from './models/vehicle';

@Component({
  selector   : 'wor-vehicle-info',
  template: require('./vehicle.info.component.html')
})
export class VehicleInfoComponent implements OnInit {
  @Input() vehicle : VehicleModel;

  public companyService   : any;
  public fleetCodeLabel   : string;
  public plateNumberLabel : string;
  public vinNumberLabel   : string;

  constructor (
    @Inject('$translate')
    public $translate     : ng.translate.ITranslateService,
    @Inject('Company')
    Company               : any
  ) {
    this.companyService = new Company();
  }

  ngOnInit () : void {
    this.fleetCodeLabel   = this.companyService.fleetCode;
    this.plateNumberLabel = this.companyService.rego;
    this.vinNumberLabel   = this.companyService.vin;
  }
}
