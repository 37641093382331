import 'angular';

import '../api/api.module';
import '../core/core.module.ajs';
import '../customers/customers.module';
import '../grids/grids.module';
import '../messages/messages.module.ajs';

import { AddVehicleFromBranchCtrl }      from './vehicle.add-from-branch.ajs';
import { VehicleBranchHistoryLiteCtrl }  from './vehicle.branch-history';
import { VehicleFollowUpNotesLiteCtrl }  from './vehicle.follow-up-notes';
import { VehicleFormSettingsLiteCtrl }   from './vehicle.form-settings';
import { VehicleHistoryCtrl }            from './vehicle.history.ajs';
import { VehicleHistoryReportCtrl }      from './vehicle.history.report.ajs';
import { VehicleHistoryService }         from './vehicle.history.service.ajs';
import { VehicleLiteCtrl }               from './vehicle';
import { VehicleService }                from './vehicle.service.ajs';
import { VehiclesLiteCtrl }              from './vehicles';
import { VehicleTransferOwnerLiteCtrl }  from './vehicle.transfer-owner';

angular.module('app-lite.vehicles', [
  'app-lite.api',
  'app-lite.core',
  'app-lite.customers',
  'app-lite.grids',
  'app-lite.messages'
]).controller('AddVehicleFromBranchCtrl', AddVehicleFromBranchCtrl)
  .controller('VehicleBranchHistoryLiteCtrl', VehicleBranchHistoryLiteCtrl)
  .controller('VehicleFollowUpNotesLiteCtrl', VehicleFollowUpNotesLiteCtrl)
  .controller('VehicleFormSettingsLiteCtrl', VehicleFormSettingsLiteCtrl)
  .controller('VehicleHistoryCtrl', VehicleHistoryCtrl)
  .controller('VehicleHistoryReportCtrl', VehicleHistoryReportCtrl)
  .controller('VehicleLiteCtrl', VehicleLiteCtrl)
  .controller('VehiclesLiteCtrl', VehiclesLiteCtrl)
  .controller('VehicleTransferOwnerLiteCtrl', VehicleTransferOwnerLiteCtrl)
  .service('vehicleApi', VehicleService)
  .service('vehicleHistoryApi', VehicleHistoryService);
