import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ControlContainer, NgForm }                       from '@angular/forms';

@Component({
  selector   : 'wor-text-control',
  template: require('./text-control.component.html'),

  viewProviders: [
    {
      provide    : ControlContainer,
      useExisting: NgForm
    }
  ]
})
export class TextControlComponent {
  @Input() autocomplete ?: string = 'one-time-code';
  @Input() label        ?: string;
  @Input() maxlength     = 255;
  @Input() model         : any;
  @Input() name          : string;
  @Input() type          = 'text'

  _disabled : boolean;
  _required : boolean;

  /**
   * if these flags are present in the html
   * but not set to any value then their value will
   * be an empty string. If that's the case, set an
   * internal variable to true here.
   */
  @Input() set disabled ( _disabled : string | boolean ) {
    this._disabled = _disabled === '' || !!_disabled;
  }

  @Input() set required ( _required : string | boolean ) {
    this._required = _required === '' || !!_required;
  }

  @Output() blur       : EventEmitter<Event> = new EventEmitter();
  @Output() focus      : EventEmitter<Event> = new EventEmitter();
  @Output() modelChange: EventEmitter<any>   = new EventEmitter();

  constructor (
    @Inject('$translate')
    public $translate     : ng.translate.ITranslateService
  ) {}
}
