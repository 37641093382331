import { NgModule }     from '@angular/core';

import { OnlinePaymentComponent }           from './online-payment.component';
import { OnlinePaymentCustomerComponent }   from './online-payment.customer.component';
import { OnlinePaymentErrorComponent }      from './online-payment.error.component';
import { OnlinePaymentSmsRequestComponent } from './online-payment.sms-request.component';
import { OnlinePaymentsService }            from './online-payments.service';
import { OnlinePaymentSuccessComponent }    from './online-payment.success.component';
import { SharedModule }                     from '../shared/shared.module';
import { TableModule }                      from '../table/table.module';
import { TillpaymentsModule }               from '../tillpayments/tillpayments.module';
import { VehiclesModule }                   from '../vehicles/vehicles.module';


@NgModule({
  declarations: [
    OnlinePaymentComponent,
    OnlinePaymentCustomerComponent,
    OnlinePaymentErrorComponent,
    OnlinePaymentSmsRequestComponent,
    OnlinePaymentSuccessComponent
  ],
  entryComponents: [
    OnlinePaymentComponent,
    OnlinePaymentCustomerComponent,
    OnlinePaymentErrorComponent,
    OnlinePaymentSmsRequestComponent,
    OnlinePaymentSuccessComponent
  ],
  imports: [
    SharedModule,
    TableModule,
    TillpaymentsModule,
    VehiclesModule
  ],
  providers: [
    OnlinePaymentsService
  ]
})
export class OnlinePaymentsModule {}
