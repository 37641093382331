import { Component, Inject, Input, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI }               from 'ng-block-ui';
import { UIRouter }                         from '@uirouter/core';

import { CustomerModel }                       from '../customers/models/customer';
import { InvoiceModel }                        from '../invoices/models/invoice';
import { OnlinePaymentInvoiceAndSessionModel } from './types/online-payment.invoice-and-session';
import { OnlinePaymentSessionModel }           from './types/online-payment.session';
import { OnlinePaymentsService }               from './online-payments.service';
import { StripeService }                       from '../stripe/stripe.service.ajs';
import { TillpaymentsPaymentModel }            from '../tillpayments/models/tillpayments.payment';
import { TillpaymentsService }                 from '../tillpayments/tillpayments.service';
import { VehicleModel }                        from '../vehicles/models/vehicle';
import { WorkshopPayService }                  from '../workshoppay/workshoppay.service.ajs';

@Component({
  selector   : 'wor-online-payment',
  template: require('./online-payment.component.html')
})
export class OnlinePaymentComponent implements OnInit {
  @BlockUI('block') block : NgBlockUI;

  @Input() data     : OnlinePaymentInvoiceAndSessionModel;
  @Input() payment ?: TillpaymentsPaymentModel;
  @Input() session  : OnlinePaymentSessionModel;
  @Input() token    : string;

  companyService: any;
  customer      : CustomerModel;
  invoice       : InvoiceModel;
  paymentLink   : string;
  surchargeLabel: string;
  taxLabel      : string;
  vehicle       : VehicleModel;

  constructor (
    @Inject('$translate')
    public $translate            : ng.translate.ITranslateService,
    @Inject('Company')
    private Company              : any,
    private onlinePaymentsService: OnlinePaymentsService,
    private router               : UIRouter,
    private stripeService        : StripeService,
    private tillpaymentsService  : TillpaymentsService,
    private workshopPayService   : WorkshopPayService
  ) {}

  ngOnInit () : void {
    this.customer = this.data.invoice.customer;
    this.invoice  = this.data.invoice;
    this.vehicle  = this.data.invoice.vehicle;

    this.companyService = new this.Company(this.data.company_settings);

    /**
   * the companyService inside of the workshopPayApi gets set
   * at runtime. Because we get the company object in the
   * payment call here, we need to override the instance
   * with the right one containing the company info. Since
   * we get that company object here, we will override it
   * here.
   */
    this.workshopPayService.overrideCompanyInstance(this.companyService);

    if (this.companyService.hasIntegration('stripe')) {
      this.surchargeLabel = this.stripeService.getSurchargeLabel();
    }

    this.taxLabel = this.onlinePaymentsService.getTaxLabel();
  }

  getInvoiceLink () : string {
    return this.onlinePaymentsService.getInvoiceLink(this.token);
  }

  hasSurcharges(): boolean {
    return (
      (this.companyService.hasIntegration('stripe') && this.stripeService.hasSurcharges(this.invoice))
        ||
      (this.companyService.hasIntegration('tillpayments-online') && this.tillpaymentsService.hasSurcharges(this.invoice))
    );
  }

  isInvoiceClosed () : boolean {
    return this.onlinePaymentsService.isInvoiceClosed(this.invoice);
  }

  payNow () : void {
    this.workshopPayService.checkout(this.invoice, this.token)
    .then(() => {
      this.router.stateService.go('app.payment-success', {
        token: this.token
      }, {
        location: 'replace'
      });
    });
  }
}
