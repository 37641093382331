import {
  Component,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';

import {
  delay,
  filter,
  from,
  Observable,
  of,
  repeatWhen,
  Subscription,
  switchMap,
  take,
  tap,
  throwError
} from 'rxjs';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbActiveModal }     from '@ng-bootstrap/ng-bootstrap';

import { InvoiceModel }                     from '../invoices/models/invoice';
import { MessagesServiceAjs }               from '../messages/messages.service.ajs';
import { TillpaymentsService }              from './tillpayments.service';
import { TillpaymentsTerminalModel }        from './models/tillpayments.terminal';
import { TillpaymentsTransactionModel }     from './models/tillpayments.transaction';
import { TillpaymentsTransactionTypeModel } from './models/tillpayments.transaction-type';

@Component({
  selector   : 'wor-tillpayments-transaction',
  template: require('./tillpayments.transaction.component.html')
})
export class TillpaymentsTransactionComponent implements OnDestroy, OnInit {
  pollingIntervalDelay = 3000;
  pollingLimit         = 100;

  pollingSubscription: Subscription;
  transaction        : TillpaymentsTransactionModel;

  @BlockUI('tillpayments-transaction') block: NgBlockUI;

  @Input() amount    : number;
  @Input() invoice ? : InvoiceModel;
  @Input() payment   : any;
  @Input() terminal ?: TillpaymentsTerminalModel;
  @Input() type      : TillpaymentsTransactionTypeModel;

  constructor (
    @Inject('$translate')
    public $translate          : ng.translate.ITranslateService,
    public activeModal         : NgbActiveModal,
    private messagesService    : MessagesServiceAjs,
    // We need to forwardRef here because the service isn't ready when this gets initialized.
    @Inject(forwardRef(() => TillpaymentsService))
    private tillpaymentsService: TillpaymentsService,
  ) {}

  ngOnDestroy () {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }

  ngOnInit () {
    this.startTransaction();
  }

  startTransaction () : void {
    if (this.tillpaymentsService.isTerminalConnected(this.terminal)){
      this.block.start(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.CREATING_TRANSACTION'));

      this.tillpaymentsService.createTransaction(this.terminal, this.payment, this.amount, this.type, this.invoice)
      .then(( transaction : TillpaymentsTransactionModel ) => {
        this.transaction = transaction;

        this.block.update(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.WAITING_FOR_TERMINAL'));

        this.pollingSubscription = of({})
        .pipe(
          switchMap(() => from(this.tillpaymentsService.getTransactionStatus(this.transaction))),
          tap(( transaction : TillpaymentsTransactionModel ) => this.transaction = transaction),
          switchMap(( transaction : TillpaymentsTransactionModel ) => {
            if (this.tillpaymentsService.didTransactionFail(transaction)) {
              return this.transaction.eft_receipt.payment_status
                ? throwError(() => (this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.TRANSACTION_FAILED', {
                  reason: this.transaction.eft_receipt.payment_status
                })))
                : throwError(() => this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.UNKNOWN_TRANSACTION_ERROR'));
            }

            return of(transaction);
          }),
          filter(( transaction : TillpaymentsTransactionModel ) => this.tillpaymentsService.isTransactionApproved(transaction)),
          repeatWhen(( notifier : Observable<any> ) => notifier.pipe(delay(this.pollingIntervalDelay), take(this.pollingLimit)))
        )
        .subscribe({
          complete: () => {

            if (!this.tillpaymentsService.isTransactionApproved(this.transaction)) {
              this.messagesService.error(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.TRANSACTION_TIMEOUT'));

              this.activeModal.dismiss();
            }
          },

          error: ( err : any ) => {
            this.messagesService.error(err);

            console.error(err);

            this.activeModal.dismiss();
          },
          next: ( transaction : TillpaymentsTransactionModel ) => {
            this.activeModal.close(transaction);
          }
        });
      })
      .catch(( err : any ) => {
        this.messagesService.error(err);

        this.activeModal.dismiss();
      });
    }
    else {
      this.messagesService.error(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.NO_ACTIVE_TERMINALS'));
    }
  }
}
