import 'angular';

import { downgradeInjectable } from '@angular/upgrade/static';

import { UserCtrl }      from './user.ajs';
import { UserService }   from './user.service';
import { UsersResolver } from './users.resolver.ajs';

angular.module('app-lite.users', [])
.controller('UserCtrl', UserCtrl)
.service('userApi', downgradeInjectable(UserService))
.run(UsersResolver);
