import { Injector, NgModule } from '@angular/core';

import { StripeStatusPipe } from './stripe.status.pipe';
import { StripeService } from './stripe.service.ajs';

@NgModule({
  declarations: [
    StripeStatusPipe
  ],
  providers: [
    StripeStatusPipe,
    {
      deps      : [ '$injector' ],
      provide   : StripeService,
      useFactory: ( $injector : Injector ) => $injector.get('stripeApi')
    }
  ]
})
export class StripeModule {}