import { Injectable } from '@angular/core';

import { ApiService }        from '../api/api.service';
import { SessionServiceAjs } from '../sessions/session.service.ajs';
import { UserModel }         from './models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor (
    private apiService    : ApiService,
    private sessionService: SessionServiceAjs
  ) {}

  get ( id = this.sessionService.base().user_id ) : ng.IPromise<UserModel> {
    return this.apiService.get('/user', id);
  }

  patch ( user : UserModel ) : ng.IPromise<UserModel> {
    return this.apiService.patch('/user', user, 'user');
  }
}
