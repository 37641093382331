import '../../../vendor/chosen/chosen.min';

// Chosen directive for all dropdowns
export function CoreDropdownChosenDirective () {
  return {
    require : "ngModel",
    restrict: 'A',
    link    (scope, element, attrs, ctrl) {
      const isClearable = !!attrs.isClearable
        && attrs.isClearable !== 'false';

      /**
        * In order for the deslect feature to work
        * there needs to be an empty option and the
        * first option. Add that here.
        */
      if (isClearable) {
        element.append('<option value=""></option>');
      }

      var options = {
        allow_single_deselect   : isClearable,
        disable_search_threshold: 10,
        inherit_select_classes  : true,
        placeholder_text_single : ' ',
        width                   : '100%'
      };

      if (attrs.onChange) {
        element.on('change', scope[attrs.onChange]);
      }

      if (attrs.onBlur) {
        element.on('chosen:hiding_dropdown', scope[attrs.onBlur]);
      }

      scope.$watch(attrs.chosen, () => {
        element.trigger('chosen:updated');
      });

      scope.$watch(() => ctrl.$modelValue, (newVal, oldVal) => {
        element.trigger('chosen:updated');
      }, true);

      scope.$watch(() => ctrl.$dirty, dirty => {
        if (dirty && ctrl.$invalid) {
          element.parent()
            .find('.chosen-container')
            .addClass('invalid');
        }
        else {
          element.parent()
            .find('.chosen-container')
            .removeClass('invalid');
        };
      }, true);

      scope.$watch(() => ctrl.$valid, valid => {
        if (!valid && ctrl.$dirty) {
          element.parent()
            .find('.chosen-container')
            .addClass('invalid');
        }
        else {
          element.parent()
            .find('.chosen-container')
            .removeClass('invalid');
        };
      }, true);

      attrs.$observe('disabled', () => element.trigger('chosen:updated'));

      element.chosen(options);
    }
  };
}