import { Component, Inject, Input } from '@angular/core';

import { Table }                     from '../table/types/table';
import { TableService }              from '../table/table.service';
import { TillpaymentsService }       from './tillpayments.service';
import { TillpaymentsTerminalModel } from './models/tillpayments.terminal';

@Component({
  selector   : 'wor-tillpayments-terminals',
  template: require('./tillpayments.terminals.component.html')
})
export class TillpaymentsTerminalsComponent {
  @Input() settings  : any;
  @Input() terminals : Array<TillpaymentsTerminalModel>;

  tableSettings : Table = {
    broadcast: 'filterTillpaymentsTerminals',
    cache    : {
      enabled: true,
      key    : this.tillpaymentsService.tableCacheKey
    },
    columns: [
      {
        field : 'name',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.NAME')
        }
      },
      {
        field : 'terminalId',
        header: {
          text: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.TERMINAL_ID')
        }
      },
      {
        field : 'deviceId',
        header: {
          text: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.DEVICE_ID')
        }
      }
    ],
    empty: {
      text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_TERMINALS_FOUND')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: this.tableService.setSortingFromCache(this.tillpaymentsService.tableCacheKey, {
      direction: 'asc',
      field    : 'name'
    })
  };

  constructor (
    @Inject('$translate')
    public $translate           : ng.translate.ITranslateService,
    private tableService        : TableService,
    private tillpaymentsService : TillpaymentsService
  ) {}
}
