import { NgModule                                } from '@angular/core';
import { CommonModule                            } from '@angular/common';
import { FormsModule                             } from '@angular/forms';
import { NgbDatepickerModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule }                             from 'ngx-quill';

import { DateControlComponent      } from './date-control/date-control.component';
import { EmailControlComponent }     from './email-control/email-control.component';
import { PhoneControlComponent }     from './phone-control/phone-control.component';
import { PostcodeControlComponent }  from './postcode-control/postcode-control.component';
import { SelectControlComponent    } from './select-control/select-control.component';
import { ShortDateControlComponent } from './date-control/short-date-control.component';
import { SwitchControlComponent    } from './switch-control/switch-control.component';
import { TextareaControlComponent }  from './textarea/textarea-control.component';
import { TextControlComponent      } from './text-control/text-control.component';
import { TextEditorComponent }       from './text-editor/text-editor.component';
import { TypeaheadControlComponent } from './typeahead-control/typeahead-control.component';
import { ValidatorsModule }          from '../validators/validators.module';

@NgModule({
  declarations: [
    DateControlComponent,
    EmailControlComponent,
    PhoneControlComponent,
    PostcodeControlComponent,
    SelectControlComponent,
    ShortDateControlComponent,
    SwitchControlComponent,
    TextControlComponent,
    TextareaControlComponent,
    TextEditorComponent,
    TypeaheadControlComponent
  ],
  entryComponents: [
    TextareaControlComponent,
    TextEditorComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    NgbDatepickerModule,
    NgbTypeaheadModule,

    DateControlComponent,
    EmailControlComponent,
    PhoneControlComponent,
    PostcodeControlComponent,
    SelectControlComponent,
    ShortDateControlComponent,
    SwitchControlComponent,
    TextareaControlComponent,
    TextControlComponent,
    TypeaheadControlComponent
  ],
  imports: [
    // Angular
    CommonModule,
    FormsModule,

    // Third Party
    NgbDatepickerModule,
    NgbTypeaheadModule,
    QuillModule.forRoot(),

    // Custom
    ValidatorsModule
  ]
})
export class ControlsModule {}
